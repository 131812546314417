<template>
  <div v-if="venue.imagePath">
    <div
      v-if="venue.imagePath"
      id="form"
      ref="form"
      class="promotion-link__background"
      :style="{background:` center / cover url('${venue.imagePath}')`}"
    />

    <div class="promotion-link__wrapper">
      <div class="promotion-link  ar-card">
        <div class="promotion-link__image">
          <el-image :src="venue.imagePath" />
        </div>
        <div class="promotion-link__content-wrapper">
          <div class="promotion-link__name">
            {{ venue.name }}
          </div>
          <div class="promotion-link__author mb4">
            {{ venue.publisherName }}
          </div>
          <a v-if="venue.items.vk" class="promotion-link__link" :href="venue.items.vk" target="_blank">
            <div class="promotion-link__link--text">
              <el-icon size="33" class="mr3">
                <VkMusic />
              </el-icon>
              VK Музыка
            </div>
            <el-icon color="#3290FF">
              <ArrowRightBold />
            </el-icon>
          </a>
          <a v-if="venue.items.yandex" class="promotion-link__link" :href="venue.items.yandex" target="_blank">
            <div class="promotion-link__link--text">
              <el-icon size="33" class="mr3">
                <YandexMusic />
              </el-icon>
              Yandex Music
            </div>
            <el-icon color="#3290FF">
              <ArrowRightBold />
            </el-icon>
          </a>
          <a v-if="venue.items.spotify" class="promotion-link__link" :href="venue.items.spotify" target="_blank">
            <div class="promotion-link__link--text">
              <el-icon size="33" class="mr3">
                <Spotify />
              </el-icon>
              Spotify
            </div>
            <el-icon color="#3290FF">
              <ArrowRightBold />
            </el-icon>
          </a>
          <a v-if="venue.items.apple" class="promotion-link__link" :href="venue.items.apple" target="_blank">
            <div class="promotion-link__link--text">
              <el-icon size="33" class="mr3">
                <AppleMusic />
              </el-icon>
              Apple music
            </div>
            <el-icon color="#3290FF">
              <ArrowRightBold />
            </el-icon>
          </a>
          <a v-if="venue.items.youtube" class="promotion-link__link" :href="venue.items.youtube" target="_blank">
            <div class="promotion-link__link--text">
              <el-icon size="33" class="mr3">
                <YoutubeMusic />
              </el-icon>

              YouTube Music
            </div>
            <el-icon color="#3290FF">
              <ArrowRightBold />
            </el-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VkMusic,
  Spotify,
  AppleMusic,
  YoutubeMusic,
  YandexMusic,
} from '@/assets/icons/index.js';

import {
  ElLoading,
} from 'element-plus';

export default {
  name: 'PromotionLink',
  components: {
    YandexMusic,
    VkMusic,
    Spotify,
    AppleMusic,
    YoutubeMusic,
  },
  props: {
    id: String,
  },
  data: () => ({
    venue: {},
    // venues: {},
  }),
  methods: {
    async getVenue() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
        target: '#form',
      });
      const { data } = await this.$axios.get(`/music-venue/get-release-venue-by-id/${this.id}`);
      const items = {};
      for (let i = 0; i < data.items.length; i++) {
        const venue = data.items[i];
        items[venue.name.toLowerCase()] = venue.link;
      }
      this.venue = {
        ...data,
        items,
      };
      loading.close();
    },
  },
  mounted() {
    this.getVenue();
  },
};
</script>

<style lang="scss">
.promotion-link{
  box-shadow: none !important;
  z-index: 2;
  overflow: hidden;
  width:20em;
  margin: auto auto;
  background:$primaryWhite;

  &__name{
    text-align: center;
    margin-bottom: 12px;
    font-size: 1.3333333333333333em;
  }

  .el-image{
    width:20em;
    height:20em;
  }

  &__author{
    color: $primaryBlue;
    text-align: center;
    font-size: 16px;
  }

  &__content-wrapper{
    padding:1.1111111111111112em;
  }

  &__link{
    background-color: $subGrey3;
    color: $primaryGrey;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius:  0.8333333333333334em;
    transition: .3s;
    margin: 5px 0;
    padding: 0.5555555555555556em ;

    &:hover{
      background-color: $subGrey2;
    }
    &:active{
      background-color: rgba(255,255,255,.4);
    }

    &--text{
      display: flex;
      align-items: center;
    }
  }

  &__background{
    transform: scale(1.2);
    z-index: 0;
    opacity: 1;
    position: fixed;
    width:100%;
    height: 100%;
    filter:blur(0.5em);

  }

  &__wrapper{
    display: flex;
    width:100vw;
    height: 100vh;
  }
}
</style>
